define("ember-svg-jar/inlined/Image-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.25 5.25a1.5 1.5 0 011.5-1.5h16.5a1.5 1.5 0 011.5 1.5v13.5a1.5 1.5 0 01-1.5 1.5H3.75a1.5 1.5 0 01-1.5-1.5V5.25zm1.5 10.81v2.69h16.5v-1.19l-3.75-3.75-.004.005-1.93 1.93a1.5 1.5 0 01-2.132 0L8.25 11.56l-.004.004L3.75 16.06zm16.5-.62V5.25H3.75v8.69l3.434-3.434a1.499 1.499 0 012.132 0l4.184 4.183.004-.004 1.93-1.93a1.5 1.5 0 012.132 0l.003.003 2.681 2.681z\" fill=\"#000\"/><path d=\"M14.625 10.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});