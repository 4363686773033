define("ember-svg-jar/inlined/InProgress-Fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2.25A9.75 9.75 0 1021.75 12 9.769 9.769 0 0012 2.25zm5.25 10.5H12a.75.75 0 01-.75-.75V6.75a.75.75 0 111.5 0v4.5h4.5a.75.75 0 110 1.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});