define("ember-svg-jar/inlined/Help-Fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2.25A9.75 9.75 0 1021.75 12 9.769 9.769 0 0012 2.25zM12 18a1.125 1.125 0 110-2.25A1.125 1.125 0 0112 18zm.75-4.584v.084a.75.75 0 11-1.5 0v-.75A.75.75 0 0112 12a1.875 1.875 0 10-1.875-1.875.75.75 0 11-1.5 0 3.375 3.375 0 114.125 3.29z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});