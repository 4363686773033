define("ember-svg-jar/inlined/Custom-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.342.833a.75.75 0 01.393.814l-1.374 6.875 5.402 2.026a.75.75 0 01.285 1.214l-10.5 11.25a.75.75 0 01-1.283-.66l1.374-6.874-5.402-2.026a.75.75 0 01-.285-1.214l10.5-11.25a.75.75 0 01.89-.155zM5.814 12.442l4.95 1.856a.75.75 0 01.471.85l-.982 4.91 7.933-8.5-4.95-1.856a.75.75 0 01-.471-.85l.982-4.91-7.933 8.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});