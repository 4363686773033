define("ember-svg-jar/inlined/File", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53v12a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V3.75c0-.398.158-.78.44-1.06zm9.31 1.06H5.25v16.5h13.5V9h-4.5a.75.75 0 01-.75-.75v-4.5zM15 4.81l2.69 2.69H15V4.81z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});