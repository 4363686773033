define("ember-svg-jar/inlined/Print-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.25 3.75A.75.75 0 016 3h12a.75.75 0 01.75.75v3h1.003c1.248 0 2.372.956 2.372 2.25v7.5a.75.75 0 01-.75.75H18.75v3.375a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V17.25H2.625a.75.75 0 01-.75-.75V9c0-1.294 1.124-2.25 2.372-2.25H5.25v-3zm-1.003 4.5c-.533 0-.872.394-.872.75v6.75H5.25v-1.5A.75.75 0 016 13.5h12a.75.75 0 01.75.75v1.5h1.875V9c0-.356-.339-.75-.872-.75H4.247zm13.003-1.5H6.75V4.5h10.5v2.25zm0 8.25H6.75v4.875h10.5V15z\" fill=\"#000\"/><path d=\"M17.625 12a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});