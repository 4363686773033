define("ember-svg-jar/inlined/Calendar-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M4.5.5a.75.75 0 01.75.75V2h7.5v-.75a.75.75 0 011.5 0V2h2.25A1.5 1.5 0 0118 3.5v15a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 010 18.5v-15A1.5 1.5 0 011.5 2h2.25v-.75A.75.75 0 014.5.5zm-.75 3H1.5v3h15v-3h-2.25v.75a.75.75 0 01-1.5 0V3.5h-7.5v.75a.75.75 0 01-1.5 0V3.5zM16.5 8h-15v10.5h15V8z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});