define("ember-svg-jar/inlined/FinancialMarket-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M20.25 4.5H3.75a.75.75 0 00-.75.75V16.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V5.25a.75.75 0 00-.75-.75z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 1.5a.75.75 0 01.75.75v1.5h7.5a1.5 1.5 0 011.5 1.5V16.5a1.5 1.5 0 01-1.5 1.5h-3.69l2.026 2.532a.75.75 0 01-1.172.936L14.64 18H9.36l-2.774 3.468a.75.75 0 11-1.172-.936L7.44 18H3.75a1.5 1.5 0 01-1.5-1.5V5.25a1.5 1.5 0 011.5-1.5h7.5v-1.5A.75.75 0 0112 1.5zM3.75 5.25V16.5h16.5V5.25H3.75zM15 7.5a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V8.25A.75.75 0 0115 7.5zM12 9a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9.75A.75.75 0 0112 9zm-3 1.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25A.75.75 0 019 10.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});