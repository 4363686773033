define("ember-svg-jar/inlined/ArrowRight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.219 12.923l-8.557 8.557a.951.951 0 01-1.346-1.345l6.935-6.934H1.63a.95.95 0 010-1.902H20.25l-6.935-6.933a.951.951 0 011.346-1.346l8.557 8.558a.95.95 0 010 1.345z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});