define("ember-svg-jar/inlined/Folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.25 20.2H3.65c-.2 0-.4 0-.6-.1-.1 0-.3-.1-.4-.3-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6V7.5c0-.4.3-.8.8-.8h17.2c.4 0 .8.2 1.1.4.3.3.4.7.4 1.1v10.6c0 .4-.2.7-.4 1-.3.3-.7.4-1.1.4zm-16.5-12v10.6l16.5-.1V8.2H3.75z\" fill=\"#000\"/><path d=\"M3.05 8.2c-.4 0-.8-.3-.8-.8V5.2c0-.4.2-.8.4-1.1.2-.3.7-.4 1.1-.4h4.9c.2 0 .4 0 .6.1.2.1.4.2.5.4l2.8 2.8c.3.3.3.8 0 1.1h-1.1l-2.7-2.9h-4.9v2.2c0 .5-.4.8-.8.8z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});