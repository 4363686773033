define("ember-svg-jar/inlined/Offline-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M7.5 11.963a7.5 7.5 0 117.5 7.5H6.75a5.25 5.25 0 110-10.5 4.97 4.97 0 011.303.169\" fill=\"#000\"/><path d=\"M4.5 3.75l15 16.5M18.178 18.797A7.51 7.51 0 0115 19.5H6.75a5.25 5.25 0 110-10.5c.44 0 .877.053 1.303.16M10.555 5.953a7.5 7.5 0 0110.04 11.044M7.5 12a7.406 7.406 0 011.04-3.807\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});