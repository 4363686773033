define("ember-svg-jar/inlined/Link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.84 5.38l-1.842 1.844a.75.75 0 11-1.061-1.06l1.856-1.857a4.884 4.884 0 016.9 6.902l-2.65 2.642-.002.001a4.868 4.868 0 01-6.893 0 .75.75 0 111.063-1.06 3.365 3.365 0 004.769 0l.002-.001 2.64-2.63a3.384 3.384 0 00-4.781-4.78zm-3.435 4.837a3.366 3.366 0 00-2.384.99l-.002.002-2.64 2.63a3.385 3.385 0 004.78 4.78l1.844-1.843a.75.75 0 011.06 1.06l-1.856 1.856a4.884 4.884 0 01-6.9-6.902l2.651-2.642h.001a4.865 4.865 0 016.893 0 .75.75 0 01-1.062 1.059 3.365 3.365 0 00-2.385-.99z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});