define("ember-svg-jar/inlined/Warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 9a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9.75A.75.75 0 0112 9z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.877 2.552a2.24 2.24 0 013.067.824l.001.002 8.248 14.247a2.254 2.254 0 01.003 2.247A2.249 2.249 0 0120.253 21H3.748a2.25 2.25 0 01-1.94-3.375l8.248-14.247v-.002c.197-.342.48-.626.82-.824zm-.17 1.198l.649.376-8.25 14.25a.752.752 0 00.646 1.124H20.25a.75.75 0 00.646-1.125L12.643 4.123a.74.74 0 00-1.285 0l-.651-.373z\" fill=\"#000\"/><path d=\"M12 18a1.125 1.125 0 100-2.25A1.125 1.125 0 0012 18z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});