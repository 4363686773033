define("ember-svg-jar/inlined/Notes-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 1.5a.75.75 0 01.75.75V3h3v-.75a.75.75 0 011.5 0V3h3v-.75a.75.75 0 011.5 0V3h1.5a1.5 1.5 0 011.5 1.5v14.25a3 3 0 01-3 3H6.75a3 3 0 01-3-3V4.5A1.5 1.5 0 015.25 3h1.5v-.75a.75.75 0 01.75-.75zm-.75 3h-1.5v14.25a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V4.5h-1.5v.75a.75.75 0 01-1.5 0V4.5h-3v.75a.75.75 0 01-1.5 0V4.5h-3v.75a.75.75 0 01-1.5 0V4.5zm1.5 7.5a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});