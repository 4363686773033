define("ember-svg-jar/inlined/ChartIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.875 1.75a.75.75 0 01.75-.75h5.25a.75.75 0 01.75.75v15h.75a.75.75 0 010 1.5H5.625a.75.75 0 010-1.5h.75v-6a.75.75 0 01.75-.75h4.5V6.25a.75.75 0 01.75-.75h4.5V1.75zm0 5.25h-3.75v9.75h3.75V7zm1.5 9.75h3.75V2.5h-3.75v14.25zm-6.75 0V11.5h-3.75v5.25h3.75z\" fill=\"#274856\"/><path clip-rule=\"evenodd\" d=\"M16.875 1.75a.75.75 0 01.75-.75h5.25a.75.75 0 01.75.75v15h.75a.75.75 0 010 1.5H5.625a.75.75 0 010-1.5h.75v-6a.75.75 0 01.75-.75h4.5V6.25a.75.75 0 01.75-.75h4.5V1.75zm0 5.25h-3.75v9.75h3.75V7zm1.5 9.75h3.75V2.5h-3.75v14.25zm-6.75 0V11.5h-3.75v5.25h3.75z\"/>",
    "attrs": {
      "width": "30",
      "height": "27",
      "viewBox": "0 0 30 27",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});