define("ember-svg-jar/inlined/FileXls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53V12a.75.75 0 01-1.5 0V9h-4.5a.75.75 0 01-.75-.75v-4.5H5.25V12a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06zM15 4.81l2.69 2.69H15V4.81zm2.375 11.744c-.067.057-.125.148-.125.321v.019a.382.382 0 00.089.063c.178.1.444.174.854.283l.022.006c.348.092.823.219 1.207.452.446.272.828.729.828 1.427 0 .707-.303 1.258-.826 1.593-.468.3-1.035.376-1.518.376h-.002a3.112 3.112 0 01-1.852-.618.75.75 0 01.896-1.202c.277.206.614.319.96.32.36 0 .59-.065.707-.14a.243.243 0 00.091-.093.501.501 0 00.044-.236v-.009c0-.045 0-.07-.109-.137-.171-.104-.433-.183-.833-.29-.022-.005-.044-.01-.067-.017-.335-.088-.782-.207-1.142-.41a1.743 1.743 0 01-.588-.524 1.502 1.502 0 01-.261-.863c0-.576.223-1.096.649-1.46.412-.354.952-.509 1.507-.509h.002a3.112 3.112 0 011.853.618.75.75 0 01-.897 1.202 1.613 1.613 0 00-.959-.32c-.287 0-.45.08-.53.148zm-.123.345v-.002.002zM4.425 15.15a.75.75 0 011.05.15l1.088 1.45L7.65 15.3a.75.75 0 111.2.9L7.5 18l1.35 1.8a.75.75 0 11-1.2.9l-1.088-1.45-1.087 1.45a.75.75 0 11-1.2-.9l1.35-1.8-1.35-1.8a.75.75 0 01.15-1.05zM11.25 15a.75.75 0 01.75.75v3.75h1.875a.75.75 0 010 1.5H11.25a.75.75 0 01-.75-.75v-4.5a.75.75 0 01.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});