define("ember-svg-jar/inlined/UserGroup-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g opacity=\".2\" fill=\"#000\"><path d=\"M12 16.875a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zM5.625 10.875a3 3 0 100-6 3 3 0 000 6zM18.375 10.875a3 3 0 100-6 3 3 0 000 6z\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.18 5.696a2.25 2.25 0 10-.565 4.429h.011a.75.75 0 010 1.5h-.012a4.857 4.857 0 00-3.888 1.949.75.75 0 01-1.202-.898 6.357 6.357 0 012.57-2.03 3.75 3.75 0 116.21-3.474.75.75 0 11-1.473.281A2.25 2.25 0 006.18 5.695zm12.893.04a2.25 2.25 0 00-2.907 1.717.75.75 0 11-1.473-.281 3.75 3.75 0 116.21 3.474 6.35 6.35 0 012.573 2.03.75.75 0 01-1.202.898 4.856 4.856 0 00-3.898-1.949h-.006a.75.75 0 01.004-1.5 2.25 2.25 0 00.699-4.39zM12 10.124a3 3 0 100 6 3 3 0 000-6zm-4.5 3a4.5 4.5 0 117.23 3.577 6.761 6.761 0 013.346 3.218.75.75 0 01-1.348.659 5.26 5.26 0 00-9.452 0 .75.75 0 01-1.348-.659 6.76 6.76 0 013.343-3.217A4.493 4.493 0 017.5 13.125z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});