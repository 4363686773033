define("ember-svg-jar/inlined/MapPinLine-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M12 2.25a7.5 7.5 0 00-7.5 7.5c0 6.75 7.5 12 7.5 12s7.5-5.25 7.5-12a7.5 7.5 0 00-7.5-7.5zm0 10.5a3 3 0 110-5.999 3 3 0 010 5.999z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3a6.75 6.75 0 00-6.75 6.75c0 3.105 1.734 5.932 3.566 8.04A23.64 23.64 0 0012 20.812a23.633 23.633 0 003.184-3.023c1.832-2.108 3.566-4.935 3.566-8.04A6.75 6.75 0 0012 3zM9.888 21a24.954 24.954 0 01-2.204-2.227C5.766 16.568 3.75 13.395 3.75 9.75a8.25 8.25 0 1116.5 0c0 3.645-2.016 6.818-3.934 9.023A24.956 24.956 0 0114.112 21h4.638a.75.75 0 010 1.5H5.25a.75.75 0 010-1.5h4.638zM12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});