define("ember-svg-jar/inlined/FileDoc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53V12a.75.75 0 01-1.5 0V9h-4.5a.75.75 0 01-.75-.75v-4.5H5.25V12a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06zM15 4.81l2.69 2.69H15V4.81zM3.375 15.75a.75.75 0 01.75-.75h1.313a3 3 0 010 6H4.125a.75.75 0 01-.75-.75v-4.5zm1.5.75v3h.563a1.5 1.5 0 100-3h-.563zm7.313 0c-.665 0-1.313.609-1.313 1.5s.648 1.5 1.313 1.5c.664 0 1.312-.609 1.312-1.5s-.648-1.5-1.313-1.5zM9.374 18c0-1.594 1.2-3 2.813-3C13.8 15 15 16.406 15 18s-1.2 3-2.813 3c-1.613 0-2.812-1.406-2.812-3zm9.184-1.5c-.668.002-1.309.606-1.309 1.5s.641 1.498 1.31 1.5c.3-.003.588-.117.809-.32a.75.75 0 111.012 1.108 2.719 2.719 0 01-1.813.712h-.006c-1.618 0-2.812-1.402-2.812-3s1.194-3 2.813-3h.006c.67.005 1.317.26 1.812.712a.75.75 0 11-1.012 1.107 1.219 1.219 0 00-.81-.319z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});