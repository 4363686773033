define("ember-svg-jar/inlined/FilePdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53V12a.75.75 0 01-1.5 0V9h-4.5a.75.75 0 01-.75-.75v-4.5H5.25V12a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06zM15 4.81l2.69 2.69H15V4.81zM3.75 15.75A.75.75 0 014.5 15H6a2.25 2.25 0 010 4.5h-.75v.75a.75.75 0 01-1.5 0v-4.5zM5.25 18H6a.75.75 0 100-1.5h-.75V18zm4.688-2.25a.75.75 0 01.75-.75H12a3 3 0 010 6h-1.313a.75.75 0 01-.75-.75v-4.5zm1.5.75v3H12a1.5 1.5 0 100-3h-.563zm5.437-.75a.75.75 0 01.75-.75h2.625a.75.75 0 010 1.5h-1.875v1.125h1.5a.75.75 0 010 1.5h-1.5v1.125a.75.75 0 01-1.5 0v-4.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});