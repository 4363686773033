define("ember-svg-jar/inlined/User-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M12 3.75a8.25 8.25 0 00-6.167 13.73 7.5 7.5 0 013.383-2.695 4.5 4.5 0 115.569 0 7.49 7.49 0 013.381 2.696A8.25 8.25 0 0012 3.75zm6.557 15.466A9.725 9.725 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.725 9.725 0 003.272 7.287A9.713 9.713 0 0012 21.75a9.713 9.713 0 006.557-2.534zm-1.5-.697a6 6 0 00-10.114 0A8.215 8.215 0 0012 20.25c1.905 0 3.66-.646 5.056-1.73zM12 8.25a3 3 0 100 6 3 3 0 000-6z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});