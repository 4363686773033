define("ember-svg-jar/inlined/Home-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M14.25 19.5V15a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v4.5a.75.75 0 01-.75.75H4.5a.75.75 0 01-.75-.75v-8.672a.778.778 0 01.244-.553l7.5-6.816a.75.75 0 011.012 0l7.5 6.816a.776.776 0 01.244.553V19.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.989 2.905a1.5 1.5 0 012.022 0l.002.001L20.51 9.72l.01.01A1.529 1.529 0 0121 10.816V19.5a1.5 1.5 0 01-1.5 1.5H15a1.5 1.5 0 01-1.5-1.5V15h-3v4.5A1.5 1.5 0 019 21H4.5A1.5 1.5 0 013 19.5v-8.684a1.529 1.529 0 01.479-1.086l.01-.01 7.499-6.814v-.001zm-6.482 7.917l7.491-6.808.002-.001.002.001 7.49 6.808a.028.028 0 01.008.016V19.5H15V15a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 15v4.5H4.5v-8.662a.028.028 0 01.007-.016z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});