define("ember-svg-jar/inlined/Transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2828_24780)\"><path clip-rule=\"evenodd\" d=\"M8.03 3.97a.75.75 0 010 1.06L6.31 6.75H19.5a.75.75 0 010 1.5H6.31l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0zm7.94 9a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H4.5a.75.75 0 010-1.5h13.19l-1.72-1.72a.75.75 0 010-1.06z\"/></g><defs><clipPath id=\"clip0_2828_24780\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "data-ember-extension": "1"
    }
  };
});