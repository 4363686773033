define("ember-svg-jar/inlined/Transport-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.6 21c-1.4 0-2.6-1-2.9-2.2H9.3C8.9 20 7.8 21 6.4 21s-2.6-1-2.9-2.2H2.2c-.4 0-.8-.2-1.1-.4-.3-.3-.4-.7-.4-1.1V6.8c0-.4.2-.8.4-1.1.2-.3.7-.4 1.1-.4h14.3c.4 0 .8.3.8.8v.8h3.2c.3 0 .6.1.8.3.2.2.4.4.6.7l1.3 3.3v6.4c0 .4-.2.8-.4 1.1-.3.3-.7.4-1.1.4h-1.2c-.3.9-1.5 1.9-2.9 1.9zm0-4.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.6-1.5-1.5-1.5zm-11.2 0c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zm14.1.7h1.2V12h-4.5v3h.4c1.4 0 2.6 1 2.9 2.2zm-11.2 0h5.4c.2-.6.5-1.2 1-1.6v-1.4H2.2v3h1.2C3.8 16 5 15 6.4 15s2.5 1 2.9 2.2zm-7.1-4.4h13.5v-6H2.2v6zm15-2.3h4.1l-.9-2.3h-3.2v2.3z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});