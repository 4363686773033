define("ember-svg-jar/inlined/FolderOpen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.69 4.94a1.5 1.5 0 011.06-.44h5c.322.001.636.106.894.298l.002.001 2.605 1.95a.01.01 0 00.005.001h6.494a1.5 1.5 0 011.5 1.5v1.5h1.21a1.5 1.5 0 011.424 1.969v.003l-2.672 8.015a.75.75 0 01-.712.513H3a.75.75 0 01-.75-.75V6c0-.398.158-.78.44-1.06zm16.06 4.81v-1.5h-6.5a1.51 1.51 0 01-.894-.298l-.002-.001L8.749 6A.01.01 0 008.744 6H3.75v8.883l1.369-4.102v-.002A1.5 1.5 0 016.544 9.75m-2.503 9l2.502-7.497v-.003H21.46l-2.5 7.5H4.041zm2.503-9H18.75 6.544z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});