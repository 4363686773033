define("ember-svg-jar/inlined/Barcode-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.25 4.5A.75.75 0 013 3.75h3.75a.75.75 0 010 1.5h-3v3a.75.75 0 01-1.5 0V4.5zm14.25 0a.75.75 0 01.75-.75H21a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0v-3h-3a.75.75 0 01-.75-.75zm-9 3a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zm3 0a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zm3 0a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zm3 0a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zM3 15a.75.75 0 01.75.75v3h3a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75v-3.75A.75.75 0 013 15zm18 0a.75.75 0 01.75.75v3.75a.75.75 0 01-.75.75h-3.75a.75.75 0 010-1.5h3v-3A.75.75 0 0121 15z\" fill=\"#274856\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});