define("ember-svg-jar/inlined/Weight-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M2.25 17.25v-2.147c0-5.39 4.331-9.834 9.713-9.853A9.75 9.75 0 0121.75 15v2.25A.75.75 0 0121 18H3a.75.75 0 01-.75-.75z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.25 6.031V8.25a.75.75 0 001.5 0V6.031a9 9 0 017.72 5.926l-2.148.575a.75.75 0 10.388 1.45l2.148-.576c.094.525.142 1.058.142 1.594v2.25h-9.784l5.487-7.147a.75.75 0 10-1.19-.913l-6.188 8.06H3v-2.147c0-.578.054-1.144.157-1.693l2.133.571a.75.75 0 10.388-1.449l-2.125-.569C4.738 8.72 7.7 6.328 11.25 6.031zM12 4.5a10.5 10.5 0 0110.123 7.713.793.793 0 01.04.145A10.5 10.5 0 0122.5 15v2.25a1.5 1.5 0 01-1.5 1.5H3a1.5 1.5 0 01-1.5-1.5v-2.147c0-.906.114-1.787.328-2.63a.751.751 0 01.102-.373c1.28-4.357 5.268-7.583 10.03-7.6m.04 0h-.04.04z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});