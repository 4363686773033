define("ember-svg-jar/inlined/Personnel-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.75 3.75a1.5 1.5 0 011.5-1.5h13.5a1.5 1.5 0 011.5 1.5v16.5a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V3.75zm15 0H5.25v16.5h13.5V3.75zM8.25 6A.75.75 0 019 5.25h6a.75.75 0 010 1.5H9A.75.75 0 018.25 6zM12 10.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm2.526 5.022a3.75 3.75 0 10-5.052 0A6.376 6.376 0 006.9 17.55a.75.75 0 101.2.9 4.874 4.874 0 017.8 0 .75.75 0 101.2-.9 6.374 6.374 0 00-2.574-2.028z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});