define("ember-svg-jar/inlined/Map-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.818 3.022a.75.75 0 01.517.057l5.752 2.876 5.731-1.433a.75.75 0 01.932.728v13.5a.75.75 0 01-.568.728l-6 1.5a.75.75 0 01-.517-.057l-5.752-2.876-5.731 1.433a.75.75 0 01-.932-.728V5.25a.75.75 0 01.568-.728l6-1.5zm.932 13.765l4.5 2.25V7.213l-4.5-2.25v11.822zM8.25 4.71v11.953l-4.5 1.125V5.836l4.5-1.125zm7.5 2.625v11.953l4.5-1.125V6.211l-4.5 1.125z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});