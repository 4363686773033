define("ember-svg-jar/inlined/Warning-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.192 17.625L13.95 3.375a2.25 2.25 0 00-3.9 0l-8.24 14.25A2.24 2.24 0 003.75 21h16.5a2.24 2.24 0 001.94-3.375zM11.25 9.75a.75.75 0 111.5 0v3.75a.75.75 0 01-1.5 0V9.75zm.75 8.25a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});