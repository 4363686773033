define("ember-svg-jar/inlined/Comment-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M14.034 18.366l-1.387 2.315a.76.76 0 01-1.294 0l-1.387-2.315A.74.74 0 009.328 18H3.75a.75.75 0 01-.75-.75v-12a.75.75 0 01.75-.75h16.5a.75.75 0 01.75.75v12a.75.75 0 01-.75.75h-5.578a.74.74 0 00-.638.366z\" fill=\"#000\"/><path d=\"M9 9.75h6M9 12.75h6M14.034 18.366l-1.387 2.315a.76.76 0 01-1.294 0l-1.387-2.315A.74.74 0 009.328 18H3.75a.75.75 0 01-.75-.75v-12a.75.75 0 01.75-.75h16.5a.75.75 0 01.75.75v12a.75.75 0 01-.75.75h-5.578a.74.74 0 00-.638.366v0z\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});