define("ember-svg-jar/inlined/Seal-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M11.503 2.428l-7.566 1.51-1.509 7.565a.75.75 0 00.206.675l9.788 9.787a.741.741 0 001.06 0l8.483-8.484a.74.74 0 000-1.06l-9.787-9.787a.75.75 0 00-.675-.206z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.359 1.692a1.5 1.5 0 011.35.412l9.784 9.785a1.49 1.49 0 010 2.125l-8.479 8.48a1.49 1.49 0 01-2.125 0l-9.784-9.785a1.5 1.5 0 01-.413-1.35v-.003l1.51-7.565a.75.75 0 01.589-.59l7.565-1.508.003-.001zm.288 1.472L4.575 4.575l-1.41 7.072 9.787 9.788 8.483-8.483-9.788-9.788z\" fill=\"#000\"/><path d=\"M7.875 9a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});