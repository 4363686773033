define("ember-svg-jar/inlined/Route-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M18.75 21a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 5.25a.75.75 0 01.75-.75h9a3.75 3.75 0 110 7.5h-9a3 3 0 000 6h9.095a3.001 3.001 0 015.905.75 3 3 0 01-5.905.75H6.75a4.5 4.5 0 110-9h9a2.25 2.25 0 000-4.5h-9A.75.75 0 016 5.25zm12.75 12a1.5 1.5 0 100 3 1.5 1.5 0 000-3z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});