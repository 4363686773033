define("ember-svg-jar/inlined/vault-menu-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>white_logo</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill-opacity=\".01\" fill=\"#333\" d=\"M0 0h512v512H0z\"/><g fill-rule=\"nonzero\"><path d=\"M255.062 390.731h-7.614L158.4 114.979l-14.897-45.682H55.117v41.71c12.91 1.655 26.152 2.648 39.062 3.641l111.89 335.007h86.4l48.993-145.324-30.124-93.02-56.276 179.42z\" fill=\"#FFF\"/><path d=\"M368.938 69.297c-19.862 23.172-26.814 45.682-26.814 45.682l-16.22 50.98 11.917 37.075 18.538 57.27 48.993-145.325c5.958-17.213 14.896-32.441 25.158-45.682 24.497-32.11 54.621-50.98 54.621-50.98-58.593.331-94.676 26.152-116.193 50.98z\" fill=\"#37A936\"/><path d=\"M340.138 60.69c-27.476-11.918-57.6-18.538-89.048-18.538-28.8 0-56.607 5.627-82.097 15.558l.993 2.98 7.614 23.503c22.841-9.269 47.338-14.234 73.49-14.234 26.151 0 51.31 5.296 74.151 14.565 3.642-6.952 8.607-15.227 14.897-23.834zM475.531 266.924c0-50.98-17.214-97.986-45.683-135.724l-11.255 32.772c18.538 29.794 29.131 65.214 29.131 102.952 0 61.242-27.807 117.848-76.138 155.255h-40.055l-9.269 32.11c-21.186 8.939-45.683 13.904-71.172 13.904-26.483 0-51.31-5.296-74.483-14.565l-8.938-31.449h-37.076c-48.33-37.738-76.138-94.013-76.138-155.255 0-7.945.662-15.89 1.655-23.834 3.642-26.814 12.91-51.973 26.483-74.152l-.662-1.986c0-.331-16.883-24.828-17.545-23.835-10.924 9.6-34.427 41.71-37.076 112.552-.33 3.641-.33 7.283-.33 11.255v2.98c0 124.137 100.634 224.44 224.44 224.44 123.808 0 224.442-100.634 224.442-224.44v-1.656c-.331-.662-.331-.993-.331-1.324z\" fill=\"#FFF\"/></g></g>",
    "attrs": {
      "width": "512",
      "height": "512",
      "viewBox": "0 0 512 512",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});