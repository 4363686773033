define("ember-svg-jar/inlined/Help-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18z\" fill=\"#000\"/><path d=\"M12 18a1.125 1.125 0 100-2.25A1.125 1.125 0 0012 18z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.458-4.993a3.375 3.375 0 112.042 6.409v.084a.75.75 0 01-1.5 0v-.75A.75.75 0 0112 12a1.875 1.875 0 10-1.875-1.875.75.75 0 01-1.5 0 3.375 3.375 0 012.083-3.118z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});