define("ember-svg-jar/inlined/Image-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M3 15.75l4.716-4.716a.75.75 0 011.068 0l4.182 4.182a.75.75 0 001.068 0l1.932-1.932a.75.75 0 011.068 0L21 17.25v-12a.75.75 0 00-.75-.75H3.75a.75.75 0 00-.75.75v10.5z\" fill=\"#000\"/><path d=\"M20.25 4.5H3.75a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V5.25a.75.75 0 00-.75-.75z\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M3 15.75l4.716-4.716a.75.75 0 011.068 0l4.182 4.181a.75.75 0 001.068 0l1.932-1.93a.751.751 0 011.068 0L21 17.25\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.625 10.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});