define("ember-svg-jar/inlined/Comment-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.69 4.19a1.5 1.5 0 011.06-.44h16.5a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 01-1.5 1.5h-5.572v.001l-1.392 2.323a1.51 1.51 0 01-2.572 0l-.004-.007-1.388-2.317H3.75a1.5 1.5 0 01-1.5-1.5v-12c0-.398.158-.78.44-1.06zm17.56 1.06H3.75v12h5.58a1.49 1.49 0 011.28.733l1.383 2.306a.01.01 0 00.015 0l1.382-2.306a1.491 1.491 0 011.28-.733h5.58v-12zm-12 4.5A.75.75 0 019 9h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zm0 3A.75.75 0 019 12h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});