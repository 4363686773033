define("ember-svg-jar/inlined/FarmTank-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M3 12v2.84a2 2 0 001.008 1.736l5.53 3.16a2 2 0 00.993.264h2.938a2 2 0 00.992-.264l5.531-3.16A2 2 0 0021 14.839V12H3z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.763 2.289a.75.75 0 01.949.474l.162.487h6.252l.163-.487a.75.75 0 011.423.474l-.005.013H19A2.75 2.75 0 0121.75 6v8.84a2.75 2.75 0 01-1.386 2.387l-.614.351V22h-1.5v-3.565l-3.417 1.953a2.75 2.75 0 01-1.364.362H10.53a2.75 2.75 0 01-1.364-.362L5.75 18.435V22h-1.5v-4.422l-.614-.35A2.75 2.75 0 012.25 14.84V6A2.75 2.75 0 015 3.25h2.293l-.005-.013a.75.75 0 01.475-.948zm.03 2.461H5c-.69 0-1.25.56-1.25 1.25v8.84c0 .448.24.862.63 1.085l5.53 3.16c.19.108.404.165.621.165h2.938c.217 0 .431-.057.62-.165l5.531-3.16c.39-.223.63-.637.63-1.085V6c0-.69-.56-1.25-1.25-1.25h-2.793L14.75 9.122V17a.75.75 0 01-1.5 0v-1.25h-2.5V17a.75.75 0 01-1.5 0V9.122L7.793 4.75zm2.957 9.5h2.5v-1.5h-2.5v1.5zm0-3h2.5V8.88l1.376-4.129H9.374L10.75 8.88v2.371z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});