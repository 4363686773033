define("ember-svg-jar/inlined/FileCsv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53V12a.75.75 0 01-1.5 0V9h-4.5a.75.75 0 01-.75-.75v-4.5H5.25V12a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06zM15 4.81l2.69 2.69H15V4.81zm-3.625 11.744c-.067.057-.125.148-.125.321v.019a.382.382 0 00.089.063c.178.1.444.174.854.283l.023.006c.347.092.823.219 1.206.452.446.272.828.729.828 1.427 0 .707-.303 1.258-.826 1.593-.468.3-1.035.376-1.518.376h-.002a3.112 3.112 0 01-1.852-.618.75.75 0 01.896-1.202c.277.206.614.319.96.32.36 0 .59-.065.707-.14a.243.243 0 00.091-.093.501.501 0 00.044-.236v-.009c0-.045 0-.07-.109-.137-.171-.104-.433-.183-.834-.29-.021-.005-.043-.01-.066-.017-.335-.088-.782-.207-1.143-.41a1.743 1.743 0 01-.588-.524 1.503 1.503 0 01-.26-.863c0-.576.223-1.096.649-1.46.413-.354.952-.509 1.507-.509h.002a3.112 3.112 0 011.853.618.75.75 0 01-.897 1.202 1.613 1.613 0 00-.959-.32c-.287 0-.45.08-.53.148zm-.123.345v-.002.002zm4.584-1.841a.75.75 0 01.981.403L18 18.3l1.183-2.839a.75.75 0 011.384.577l-1.875 4.5a.75.75 0 01-1.384 0l-1.875-4.5a.75.75 0 01.403-.98zM6.185 16.5c-.669.002-1.31.606-1.31 1.5s.641 1.498 1.31 1.5c.3-.003.588-.117.809-.32a.75.75 0 011.012 1.108A2.719 2.719 0 016.194 21h-.006c-1.619 0-2.813-1.402-2.813-3s1.194-3 2.813-3h.006c.67.005 1.317.26 1.812.712a.75.75 0 01-1.012 1.107 1.219 1.219 0 00-.81-.319z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});