define("ember-svg-jar/inlined/Eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.407 4.095C5.195 2.307 8.001.5 12 .5c4 0 6.805 1.807 8.593 3.595a14.188 14.188 0 011.956 2.45A11.99 11.99 0 0123.14 7.6l.032.067.009.02.003.006v.002s.001.001-.684.306l.685.305-.001.003-.003.007-.009.02a7.865 7.865 0 01-.151.304c-.105.2-.261.48-.472.815a14.185 14.185 0 01-1.956 2.451C18.805 13.693 15.999 15.5 12 15.5c-4 0-6.805-1.807-8.593-3.595a14.186 14.186 0 01-1.956-2.45A11.97 11.97 0 01.86 8.4a4.76 4.76 0 01-.032-.067l-.009-.02-.003-.006v-.002S.814 8.305 1.5 8l-.685-.305.001-.003.003-.007.01-.02a7.903 7.903 0 01.151-.304c.104-.2.26-.48.471-.815a14.19 14.19 0 011.956-2.451zM1.5 8l-.685-.305a.752.752 0 000 .61L1.5 8zm.839 0c.088.165.215.39.382.655.375.597.951 1.394 1.747 2.19C6.055 12.432 8.5 14 12 14s5.945-1.568 7.532-3.155a12.694 12.694 0 001.747-2.19c.168-.265.294-.49.383-.655-.089-.165-.215-.39-.383-.655a12.691 12.691 0 00-1.747-2.19C17.945 3.568 15.501 2 12 2 8.5 2 6.055 3.568 4.468 5.155a12.69 12.69 0 00-1.747 2.19c-.167.265-.294.49-.382.655zM22.5 8l.685.305a.752.752 0 000-.61L22.5 8zM12 5a3 3 0 100 6 3 3 0 000-6zM7.5 8a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z\" fill=\"#274856\"/>",
    "attrs": {
      "width": "24",
      "height": "16",
      "viewBox": "0 0 24 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});