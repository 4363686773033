define("ember-svg-jar/inlined/News-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M14.25 15H18a3.75 3.75 0 000-7.5h-3.75V15z\" fill=\"#000\"/><path d=\"M16 21.3h-.2c-.2 0-.5-.1-.7-.2l-1-.7c-.2-.1-.4-.3-.5-.5-.1-.2-.2-.5-.2-.7V7.5c0-.4.3-.8.8-.8s.8.4.8.8v11.6l1 .7 1.2-5c.1-.4.5-.6.9-.5.4.1.6.5.5.9l-1.2 5c-.1.2-.2.5-.3.6-.1.1-.4.3-.6.4-.1.1-.3.1-.5.1z\" fill=\"#000\"/><path d=\"M3.7 20c-.2 0-.4 0-.6-.1-.3-.1-.5-.3-.6-.5-.2-.2-.2-.5-.2-.8V4c0-.3.1-.6.2-.8.2-.2.4-.4.6-.5.3-.1.5-.2.8-.1.3 0 .5.2.8.3 4.6 3.9 9.5 3.9 9.5 3.9H18c1.2 0 2.3.5 3.2 1.3.9.9 1.3 2 1.3 3.2 0 1.2-.5 2.3-1.3 3.2-.8.8-2 1.3-3.2 1.3h-3.8s-4.9.1-9.5 3.9c-.2.2-.5.3-.8.3h-.2zm0-16v14.6c5.1-4.2 10.3-4.3 10.5-4.3H18c.8 0 1.6-.3 2.1-.9.5-.6.9-1.4.9-2.1 0-.8-.3-1.6-.9-2.1-.6-.6-1.3-.9-2.1-.9h-3.8C14 8.3 8.8 8.2 3.7 4z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});