define("ember-svg-jar/inlined/Factory-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M20.25 20.25v-7.5h-4.5l-6-4.5v4.5l-6-4.5v12h16.5z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.395 1.5h1.71a1.5 1.5 0 011.481 1.292v.002l1.407 9.85c.005.035.007.07.007.106v6.75h1.5a.75.75 0 010 1.5h-21a.75.75 0 010-1.5H3V8.25a.75.75 0 011.2-.6l4.8 3.6v-3a.75.75 0 011.2-.6l3.629 2.721 1.084-7.577v-.002A1.5 1.5 0 0116.396 1.5zM13.87 12.278c.045.042.094.08.149.111l1.281.961a.75.75 0 00.45.15h3.75v6h-15V9.75l4.8 3.6a.75.75 0 001.2-.6v-3l3.37 2.528zM19.385 12H16l-.803-.602 1.202-8.395V3h1.702v.003L19.385 12zM6.75 16.5a.75.75 0 01.75-.75h2.625a.75.75 0 010 1.5H7.5a.75.75 0 01-.75-.75zm6.375 0a.75.75 0 01.75-.75H16.5a.75.75 0 010 1.5h-2.625a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});