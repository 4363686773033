define("ember-svg-jar/inlined/History-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M12 20.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.245 3.173a9 9 0 11-4.608 15.188.75.75 0 011.062-1.06 7.5 7.5 0 100-10.602L4.795 8.598H6.73a.75.75 0 110 1.5H2.98a.75.75 0 01-.75-.75v-3.75a.75.75 0 111.5 0V7.54L5.637 5.64a9.002 9.002 0 014.609-2.466zM12 6.75a.75.75 0 01.75.75v4.067l3.525 2.033a.75.75 0 11-.75 1.3l-3.9-2.25a.75.75 0 01-.375-.65V7.5a.75.75 0 01.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});