define("ember-svg-jar/inlined/MilkCarton-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.402 3a.75.75 0 01.75-.75h9.939a.75.75 0 01.75.75v2.607l2.6 2.869a.749.749 0 01.309.606V21a.75.75 0 01-.75.75H4a.75.75 0 01-.75-.75V9c0-.2.08-.393.224-.534l2.928-2.883V3zm1.057 3.647L5.83 8.25h3.987a.75.75 0 110 1.5H4.75v10.5h8.5V9.082a.75.75 0 01.211-.522l1.853-1.913H7.46zm9.61.343L15.77 8.332h2.517L17.07 6.99zm2.181 2.842h-4.5V20.25h4.5V9.832zm-2.91-4.685V3.75H7.903v1.397h8.439z\" fill=\"#121010\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});