define("ember-svg-jar/inlined/Edit-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.7 21H4.5c-.375 0-.75-.15-1.05-.45-.3-.3-.45-.676-.45-1.05v-4.2c0-.226 0-.376.075-.6.075-.15.15-.376.3-.45L14.625 3c.15-.15.3-.226.45-.3.375-.15.825-.15 1.125 0 .3.074.45.15.6.3L21 7.2c.15.15.225.3.3.524.075.15.15.375.15.6 0 .225-.075.375-.15.6-.075.15-.15.375-.3.45L9.75 20.624c-.15.15-.3.225-.525.3-.15 0-.3.075-.525.075zm7.05-16.95L4.5 15.3v4.2h4.2L19.95 8.25l-4.2-4.2z\" fill=\"currentColor\"/><path d=\"M18 12a.68.68 0 01-.525-.226l-5.25-5.25c-.3-.3-.3-.75 0-1.05.3-.3.75-.3 1.05 0l5.25 5.25c.3.3.3.75 0 1.05A.68.68 0 0118 12z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});