define("ember-svg-jar/inlined/Tanker-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.25 6.825v10.35c.054.035.16.075.3.075h.92a3.001 3.001 0 015.81 0h5.44a3.001 3.001 0 015.752-.2l.943-.47c.02-.011.041-.02.063-.029.057-.074.159-.239.272-.552V6.75H10.5v6.75a.75.75 0 01-1.5 0v-2.25H7.5v2.25a.75.75 0 01-1.5 0V6.75H2.55c-.14 0-.246.04-.3.075zm.3-1.575c-.41 0-.836.115-1.178.36-.346.246-.622.645-.622 1.14v10.5c0 .495.276.894.622 1.14.342.245.768.36 1.178.36h.92a3.001 3.001 0 005.81 0h5.44a3.001 3.001 0 005.825-.06.801.801 0 00.04-.02L22 17.965c.121-.035.221-.09.295-.14.132-.087.249-.202.352-.332.206-.259.398-.627.566-1.13a.75.75 0 00.038-.237V6a.75.75 0 00-.75-.75H2.55zm4.95 1.5v.75H9v-.75H7.5zM9 9H7.5v.75H9V9zm-2.625 7.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm11.25 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});