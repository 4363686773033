define("ember-svg-jar/inlined/Download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3a.75.75 0 01.75.75v8.69l2.657-2.658a.75.75 0 111.06 1.06L12.53 14.78a.75.75 0 01-1.06 0l-3.938-3.937a.75.75 0 111.06-1.06l2.658 2.656V3.75A.75.75 0 0112 3zM3.75 13.5a.75.75 0 01.75.75v5.25h15v-5.25a.75.75 0 011.5 0v5.25a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-5.25a.75.75 0 01.75-.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});