define("ember-svg-jar/inlined/EyeSlash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.5 4.25l15 16.5M14.522 15.275a3.75 3.75 0 01-5.044-5.55\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.938 6.932C3.112 8.862 1.5 12.5 1.5 12.5s3 6.75 10.5 6.75c1.757.015 3.493-.39 5.063-1.18M19.556 16.353C21.6 14.525 22.5 12.5 22.5 12.5s-3-6.75-10.5-6.75c-.65-.001-1.3.052-1.94.16M12.703 8.815a3.74 3.74 0 013.028 3.329\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});