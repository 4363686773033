define("ember-svg-jar/inlined/Plant-duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" d=\"M0 0h256v256H0z\"/><path d=\"M138.54 141.46C106.62 88.25 149.18 35.05 239.63 40.37c5.37 90.45-47.88 133.01-101.09 101.09zM88.47 152.47c22.8-38-7.6-76-72.21-72.21-3.8 64.61 34.21 95.01 72.21 72.21z\" opacity=\".2\"/><path d=\"M138.54 141.46C106.62 88.25 149.18 35.05 239.63 40.37c5.37 90.45-47.88 133.01-101.09 101.09zM88.47 152.47c22.8-38-7.6-76-72.21-72.21-3.8 64.61 34.21 95.01 72.21 72.21zM56 120l64 64\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"16\"/><path d=\"M200 80l-61.25 61.25A64 64 0 00120 186.51V216\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 256 256"
    }
  };
});