define("ember-svg-jar/inlined/Camera-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M19.5 6h-3L15 3.75H9L7.5 6h-3A1.5 1.5 0 003 7.5V18a1.5 1.5 0 001.5 1.5h15A1.5 1.5 0 0021 18V7.5A1.5 1.5 0 0019.5 6zM12 15.75a3.374 3.374 0 110-6.749 3.374 3.374 0 010 6.749z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.376 3.334A.75.75 0 019 3h6a.75.75 0 01.624.334l1.277 1.916H19.5a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25h-15A2.25 2.25 0 012.25 18V7.5A2.25 2.25 0 014.5 5.25h2.599l1.277-1.916zM9.401 4.5L8.124 6.416a.75.75 0 01-.624.334h-3a.75.75 0 00-.75.75V18a.75.75 0 00.75.75h15a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75h-3a.75.75 0 01-.624-.334L14.599 4.5H9.4zM12 9.75A2.625 2.625 0 1012 15a2.625 2.625 0 000-5.25zm-4.125 2.625a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});