define("ember-svg-jar/inlined/Samples-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.801 14.2c-.8 0-1.8-.2-2.9-.7-2.5-1.3-4.1-.1-4.1-.1-.3.3-.8.2-1.1-.1-.3-.3-.2-.8.1-1.1.1-.1 2.3-1.8 5.7-.1 2.5 1.2 4 .1 4.1.1.3-.2.8-.2 1 .1.2.3.2.8-.1 1 0 .1-1 .9-2.7.9z\" fill=\"#000\"/><path d=\"M6.401 21.8c-.6 0-1.1-.1-1.6-.3-.5-.2-1-.5-1.4-.9-.4-.4-.7-.8-.9-1.4-.2-.5-.3-1.1-.3-1.6s.1-1.1.3-1.6c.2-.5.5-1 .9-1.4l12.2-12.1c.3-.3.8-.3 1.1 0l5.6 5.6c.2.2.3.4.2.7-.1.3-.2.5-.5.5l-2.1.7-10.6 10.6c-.3.4-.8.7-1.3.9-.5.2-1.1.3-1.6.3zm9.7-17.7l-11.6 11.6c-.3.3-.5.6-.6.9-.1.3-.2.7-.2 1 0 .3.1.7.2 1 .1.4.3.7.6.9.2.3.5.5.8.6.7.3 1.4.3 2.1 0 .3-.1.6-.3.9-.6l10.7-10.7c.1-.1.2-.1.3-.2l1.1-.4-4.3-4.1z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});