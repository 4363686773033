define("ember-svg-jar/inlined/Alert-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M5.27 9.75A6.74 6.74 0 0112.048 3c3.713.028 6.685 3.113 6.685 6.834v.666c0 3.356.703 5.306 1.321 6.375A.75.75 0 0119.408 18H4.594a.75.75 0 01-.647-1.125c.619-1.069 1.322-3.019 1.322-6.375v-.75z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 17.75a.75.75 0 01.75.75v.25a2.25 2.25 0 004.5 0v-.25a.75.75 0 011.5 0v.25a3.75 3.75 0 11-7.5 0v-.25a.75.75 0 01.75-.75z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3.621c-.794 0-1.58.167-2.314.492-.733.325-1.4.8-1.961 1.401a6.51 6.51 0 00-1.31 2.097 6.853 6.853 0 00-.461 2.474c0 3.74-.734 5.993-1.438 7.294h14.968c-.704-1.3-1.438-3.553-1.438-7.294 0-.85-.156-1.69-.46-2.474a6.512 6.512 0 00-1.31-2.097 6.038 6.038 0 00-1.962-1.401A5.708 5.708 0 0012 3.621zM9.106 2.615A7.14 7.14 0 0112 2c.993 0 1.976.21 2.894.615a7.55 7.55 0 012.453 1.753 8.143 8.143 0 011.64 2.623c.38.98.575 2.032.575 3.094 0 3.512.688 5.473 1.234 6.483a1.71 1.71 0 01.002 1.619 1.573 1.573 0 01-.553.594c-.23.142-.49.218-.756.219H4.511a1.442 1.442 0 01-.756-.22 1.573 1.573 0 01-.553-.593 1.708 1.708 0 01.001-1.619c.547-1.009 1.235-2.971 1.235-6.483 0-1.062.195-2.113.575-3.094.38-.981.937-1.872 1.64-2.623a7.55 7.55 0 012.453-1.753z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});