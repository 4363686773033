define("ember-svg-jar/inlined/Filter-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.5 21.8c-.2 0-.5-.1-.7-.2-.2-.1-.4-.3-.6-.5-.1-.2-.2-.5-.2-.7V13L2.8 6.3c-.2-.3-.3-.5-.3-.8 0-.3 0-.6.1-.8.1-.3.3-.5.5-.7.2-.2.5-.2.8-.2H20c.3 0 .6.1.8.2.2.2.4.4.6.7.1.3.2.6.1.8 0 .3-.2.6-.4.8L15 13v5.3c0 .2-.1.5-.2.7-.1.2-.3.4-.5.5l-3 2c-.2.2-.5.3-.8.3zM3.9 5.2l6.2 6.8c.3.3.4.6.4 1v7.3l3-2V13c0-.4.1-.7.4-1l6.2-6.8H3.9z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});