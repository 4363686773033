define("ember-svg-jar/inlined/Lock-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5 7.5h-3.375V4.875a4.125 4.125 0 00-8.25 0V7.5H4.5A1.5 1.5 0 003 9v10.5A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5V9a1.5 1.5 0 00-1.5-1.5zM12 15.375a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zM14.625 7.5h-5.25V4.875a2.625 2.625 0 015.25 0V7.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});