define("ember-svg-jar/inlined/Delete-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.159 2.159A2.25 2.25 0 019.75 1.5h4.5a2.25 2.25 0 012.25 2.25v.75h3.75a.75.75 0 010 1.5h-.75v13.5A1.5 1.5 0 0118 21H6a1.5 1.5 0 01-1.5-1.5V6h-.75a.75.75 0 010-1.5H7.5v-.75c0-.597.237-1.169.659-1.591zM6 6v13.5h12V6H6zm9-1.5H9v-.75A.75.75 0 019.75 3h4.5a.75.75 0 01.75.75v.75zM9.75 9a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6A.75.75 0 019.75 9zm3.75.75a.75.75 0 011.5 0v6a.75.75 0 01-1.5 0v-6z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});