define("ember-svg-jar/inlined/CircleCheckMark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 1.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM.5 7a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm9.612-1.845a.5.5 0 01-.017.707l-3.669 3.5a.5.5 0 01-.69 0l-1.831-1.75a.5.5 0 01.69-.723l1.487 1.42 3.323-3.17a.5.5 0 01.707.016z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});