define("ember-svg-jar/inlined/Temperature-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 2.25A2.25 2.25 0 009.75 4.5v9.281a.75.75 0 01-.288.591 4.125 4.125 0 105.077 0 .75.75 0 01-.289-.59V4.5A2.25 2.25 0 0012 2.25zm-2.652-.402A3.75 3.75 0 0115.75 4.5v8.931a5.625 5.625 0 11-7.5 0V4.5a3.75 3.75 0 011.098-2.652zM12 7.5a.75.75 0 01.75.75v6.859a2.626 2.626 0 11-1.5 0V8.25A.75.75 0 0112 7.5zm0 9a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});