define("ember-svg-jar/inlined/Plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M247.63 39.89a8 8 0 00-7.52-7.52c-51.76-3-93.32 12.74-111.18 42.22-11.8 19.49-11.78 43.16-.16 65.74a71.34 71.34 0 00-14.17 27L98.33 151c7.82-16.33 7.52-33.35-1-47.49-13.2-21.79-43.67-33.47-81.5-31.25a8 8 0 00-7.52 7.52c-2.23 37.83 9.46 68.3 31.25 81.5A45.82 45.82 0 0063.44 168 54.58 54.58 0 0087 162.33l25 25V216a8 8 0 0016 0v-29.49a55.61 55.61 0 0112.27-35 73.91 73.91 0 0033.31 8.4 60.9 60.9 0 0031.83-8.86c29.48-17.84 45.26-59.4 42.22-111.16zM47.81 147.6C32.47 138.31 23.79 116.32 24 88c28.32-.24 50.31 8.47 59.6 23.81 4.85 8 5.64 17.33 2.46 26.94l-24.41-24.41a8 8 0 00-11.31 11.31l24.41 24.41c-9.61 3.18-18.93 2.39-26.94-2.46zm149.31-10.22c-13.4 8.11-29.15 8.73-45.15 2l53.69-53.7a8 8 0 00-11.31-11.31L140.65 128c-6.76-16-6.15-31.76 2-45.15 13.94-23 47-35.82 89.33-34.83.96 42.32-11.84 75.42-34.86 89.36z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 256 256"
    }
  };
});