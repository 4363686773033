define("ember-svg-jar/inlined/Office-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M13.5 20.25V3.75a.75.75 0 00-.75-.75h-9a.75.75 0 00-.75.75v16.5\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.69 2.69a1.5 1.5 0 011.06-.44h9a1.5 1.5 0 011.5 1.5v4.5h6a1.5 1.5 0 011.5 1.5v9.75h.75a.75.75 0 010 1.5h-21a.75.75 0 010-1.5h.75V3.75c0-.398.158-.78.44-1.06zM3.75 19.5h9V3.75h-9V19.5zm10.5-9.75v9.75h6V9.75h-6zm-9-3A.75.75 0 016 6h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zm1.5 6A.75.75 0 017.5 12h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zm9 0a.75.75 0 01.75-.75H18a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM5.25 16.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zm10.5 0a.75.75 0 01.75-.75H18a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});