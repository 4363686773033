define("ember-svg-jar/inlined/MapPin-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3a6.75 6.75 0 00-6.75 6.75c0 3.105 1.734 5.932 3.566 8.04A23.64 23.64 0 0012 20.812a23.633 23.633 0 003.184-3.023c1.832-2.108 3.566-4.935 3.566-8.04A6.75 6.75 0 0012 3zm0 18.75l-.43.614h-.001l-.002-.002-.005-.003-.02-.014a10.138 10.138 0 01-.32-.238 25.146 25.146 0 01-3.538-3.334C5.766 16.568 3.75 13.395 3.75 9.75a8.25 8.25 0 1116.5 0c0 3.645-2.016 6.818-3.934 9.023a25.156 25.156 0 01-3.538 3.334 14.102 14.102 0 01-.32.238l-.02.014-.005.003-.002.002L12 21.75zm0 0l.43.614a.75.75 0 01-.86 0l.43-.614zM12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});