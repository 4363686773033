define("ember-svg-jar/inlined/Edit-allocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.251 11.595l-2.125 2.125a.531.531 0 01-.752 0L9.25 11.595a.532.532 0 11.752-.752l1.218 1.218v-3.81L7.5 4.532 3.782 8.251v3.81l1.217-1.218a.531.531 0 11.752.752L3.626 13.72a.53.53 0 01-.752 0L.75 11.595a.53.53 0 11.752-.752L2.72 12.06v-4.03a.531.531 0 01.155-.376L6.97 3.561V.594a.531.531 0 111.063 0V3.56l4.094 4.094a.53.53 0 01.155.376v4.03l1.218-1.218a.532.532 0 01.752.752z\" fill=\"#000\"/>",
    "attrs": {
      "width": "15",
      "height": "14",
      "viewBox": "0 0 15 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});