define("ember-svg-jar/inlined/Pickup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.72 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06L15 4.81V7a9.75 9.75 0 01-9.75 9.75.75.75 0 110-1.5A8.25 8.25 0 0013.5 7V4.81l-3.22 3.22a.75.75 0 11-1.06-1.06l4.5-4.5zM3 20.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z\" fill=\"#121010\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});