define("ember-svg-jar/inlined/Scan-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.75 3.75A.75.75 0 0116.5 3h3.75a.75.75 0 01.75.75V7.5a.75.75 0 01-1.5 0v-3h-3a.75.75 0 01-.75-.75zM3.75 15.75a.75.75 0 01.75.75v3h3a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75V16.5a.75.75 0 01.75-.75zM20.25 15.75a.75.75 0 01.75.75v3.75a.75.75 0 01-.75.75H16.5a.75.75 0 010-1.5h3v-3a.75.75 0 01.75-.75zM3 3.75A.75.75 0 013.75 3H7.5a.75.75 0 010 1.5h-3v3a.75.75 0 01-1.5 0V3.75zM6.75 8.25a1.5 1.5 0 011.5-1.5h7.5a1.5 1.5 0 011.5 1.5v7.5a1.5 1.5 0 01-1.5 1.5h-7.5a1.5 1.5 0 01-1.5-1.5v-7.5zm9 0h-7.5v7.5h7.5v-7.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});