define("ember-svg-jar/inlined/Upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M11.47 3.22a.75.75 0 011.06 0l3.938 3.937a.75.75 0 01-1.06 1.06L12.75 5.562v8.689a.75.75 0 01-1.5 0V5.56L8.593 8.219a.75.75 0 01-1.06-1.06L11.47 3.22zM3.75 13.5a.75.75 0 01.75.75v5.25h15v-5.25a.75.75 0 011.5 0v5.25a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-5.25a.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});