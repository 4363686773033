define("ember-svg-jar/inlined/Signature-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.67 1.927a1.5 1.5 0 011.646.329l.003.002 6.423 6.423.002.003a1.5 1.5 0 010 2.132l-.002.003-2.347 2.346-2.041 5.442a1.49 1.49 0 01-1.15.957h-.003L3.123 21.74a.75.75 0 01-.863-.863L4.436 7.804a1.51 1.51 0 01.95-1.155l.004-.001 5.445-2.043 2.349-2.35a1.5 1.5 0 01.487-.328zm-2.609 4.195L5.921 8.05c-.001 0 0 0 0 0a.011.011 0 00-.005.003.01.01 0 00-.002.004l-1.79 10.758 4.442-4.442a2.625 2.625 0 111.06 1.06l-4.442 4.443 10.763-1.79v-.004l1.931-5.144-6.817-6.817zm7.689 5.567l1.94-1.94-.005-.003L14.25 3.31l-.004.004-1.935 1.935 6.439 6.44zm-8.62 2.28a1.125 1.125 0 10-.098-.098.754.754 0 01.098.097z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});