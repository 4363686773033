define("ember-svg-jar/inlined/Cancelled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M5.66 6.722A8.25 8.25 0 0017.278 18.34L5.658 6.72zm-.606-1.564A9.719 9.719 0 002.25 12c0 5.385 4.365 9.75 9.75 9.75a9.72 9.72 0 006.929-2.89A9.719 9.719 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75a9.719 9.719 0 00-6.844 2.806.756.756 0 00-.102.102zm1.666.503l11.62 11.62A8.25 8.25 0 006.72 5.66z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});