define("ember-svg-jar/inlined/Offline-Fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.053 3.244a.75.75 0 00-1.106 1.012l3.675 4.041v.019A6 6 0 106.75 20.25H15a8.24 8.24 0 002.981-.553l.966 1.06a.748.748 0 001.06.046.74.74 0 00.046-1.059l-15-16.5zM15 3.75a8.175 8.175 0 00-4.884 1.603.74.74 0 00-.3.525.731.731 0 00.187.582l10.04 11.043a.758.758 0 00.554.244.73.73 0 00.553-.253A8.25 8.25 0 0015 3.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});