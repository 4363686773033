define("ember-svg-jar/inlined/FileJpg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.19 2.69a1.5 1.5 0 011.06-.44h9a.75.75 0 01.53.22l5.25 5.25c.141.14.22.331.22.53V12a.75.75 0 01-1.5 0V9h-4.5a.75.75 0 01-.75-.75v-4.5H5.25V12a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06zM15 4.81l2.69 2.69H15V4.81zM6.75 15a.75.75 0 01.75.75v3a2.25 2.25 0 01-4.5 0 .75.75 0 011.5 0 .75.75 0 101.5 0v-3a.75.75 0 01.75-.75zm2.25.75a.75.75 0 01.75-.75h1.5a2.25 2.25 0 010 4.5h-.75v.75a.75.75 0 01-1.5 0v-4.5zM10.5 18h.75a.75.75 0 100-1.5h-.75V18zm7.245-1.279a1.154 1.154 0 00-.681-.221h-.002c-.669 0-1.312.604-1.312 1.5 0 .893.64 1.497 1.307 1.5a1.266 1.266 0 00.943-.446v-.116h-.375a.75.75 0 010-1.5h1.125a.75.75 0 01.75.75v1.106a.75.75 0 01-.112.394A2.766 2.766 0 0117.073 21h-.01c-1.619 0-2.813-1.402-2.813-3s1.194-3 2.812-3c.563 0 1.112.178 1.567.51a.75.75 0 01-.884 1.211z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});