define("ember-svg-jar/inlined/Video-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M19.5 4.5h-15A1.5 1.5 0 003 6v10.5A1.5 1.5 0 004.5 18h15a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5zm-9 9.75v-6l4.5 3-4.5 3z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 5.25a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h15a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75h-15zM2.25 6A2.25 2.25 0 014.5 3.75h15A2.25 2.25 0 0121.75 6v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6zm7.896 1.589a.75.75 0 01.77.037l4.5 3a.75.75 0 010 1.248l-4.5 3a.75.75 0 01-1.166-.624v-6a.75.75 0 01.396-.661zM11.25 9.65v3.198l2.398-1.599-2.398-1.599zM8.25 21a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});