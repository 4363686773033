define("ember-svg-jar/inlined/Grain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M208 56a87.53 87.53 0 00-31.85 6c-14.32-29.7-43.25-44.46-44.57-45.12a8 8 0 00-7.16 0c-1.33.66-30.25 15.42-44.57 45.12A87.53 87.53 0 0048 56a8 8 0 00-8 8v80a88 88 0 00176 0V64a8 8 0 00-8-8zm-88 159.56A72.1 72.1 0 0156 144v-15.56A72.1 72.1 0 01120 200zm0-66.1a88 88 0 00-64-37.09V72.44A72.1 72.1 0 01120 144zM94.15 69.11c9.22-19.21 26.41-31.33 33.85-35.9 7.44 4.58 24.63 16.7 33.84 35.9A88.61 88.61 0 00128 107.36a88.57 88.57 0 00-33.85-38.25zM200 144a72.1 72.1 0 01-64 71.56V200a72.1 72.1 0 0164-71.56zm0-31.63a88 88 0 00-64 37.09V144a72.1 72.1 0 0164-71.56z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 256 256"
    }
  };
});