define("ember-svg-jar/inlined/Receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.69 4.19a1.5 1.5 0 011.06-.44h16.5a1.5 1.5 0 011.5 1.5V19.5a.75.75 0 01-1.085.67L18 18.839l-2.665 1.333a.75.75 0 01-.67 0L12 18.839 9.335 20.17a.75.75 0 01-.67 0L6 18.839 3.335 20.17A.75.75 0 012.25 19.5V5.25c0-.398.158-.78.44-1.06zm17.56 1.06H3.75v13.037l1.915-.958a.75.75 0 01.67 0L9 18.661l2.665-1.332a.75.75 0 01.67 0L15 18.661l2.665-1.332a.75.75 0 01.67 0l1.915.957V5.25zM6.375 9.75a.75.75 0 01.75-.75h9.75a.75.75 0 010 1.5h-9.75a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h9.75a.75.75 0 010 1.5h-9.75a.75.75 0 01-.75-.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});