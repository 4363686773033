define("ember-svg-jar/inlined/Settings-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 8.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM6.75 12a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.79 1.995a.75.75 0 01.616.07l2.192 1.316c.269-.012.538-.012.806 0l2.184-1.306a.75.75 0 01.61-.072 10.37 10.37 0 013.861 2.224.75.75 0 01.245.566l-.043 2.549c.148.227.284.462.407.704l2.222 1.236a.75.75 0 01.368.492c.327 1.464.33 2.982.01 4.448a.75.75 0 01-.37.496l-2.23 1.236a7.635 7.635 0 01-.407.703l.043 2.549a.75.75 0 01-.245.567 10.5 10.5 0 01-3.847 2.231.75.75 0 01-.616-.07l-2.192-1.316a9.003 9.003 0 01-.807 0l-2.183 1.306a.75.75 0 01-.61.072 10.368 10.368 0 01-3.861-2.223.75.75 0 01-.245-.567l.043-2.543a8.25 8.25 0 01-.407-.71l-2.223-1.235a.75.75 0 01-.367-.492 10.313 10.313 0 01-.01-4.449.75.75 0 01.37-.496l2.23-1.236c.123-.241.259-.476.407-.703l-.043-2.549a.75.75 0 01.245-.567 10.5 10.5 0 013.846-2.23zm-2.587 3.12l.042 2.438a.75.75 0 01-.136.442 6.15 6.15 0 00-.526.91.75.75 0 01-.316.338l-2.133 1.182a8.812 8.812 0 00.008 3.15l2.126 1.182a.75.75 0 01.314.335c.153.323.33.633.532.928a.75.75 0 01.13.436l-.04 2.428A8.869 8.869 0 008.94 20.46l2.085-1.248a.75.75 0 01.439-.104c.357.025.716.025 1.074 0a.75.75 0 01.44.105l2.09 1.255a9 9 0 002.73-1.584l-.04-2.437a.75.75 0 01.134-.442 6.15 6.15 0 00.527-.911.75.75 0 01.315-.338l2.134-1.181a8.814 8.814 0 00-.008-3.15l-2.127-1.182a.75.75 0 01-.314-.337 6.152 6.152 0 00-.527-.911.75.75 0 01-.135-.442l.041-2.438a8.869 8.869 0 00-2.737-1.576l-2.084 1.248a.75.75 0 01-.439.104 7.528 7.528 0 00-1.074 0 .75.75 0 01-.44-.105L8.933 3.532a9 9 0 00-2.73 1.583z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});