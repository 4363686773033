define("ember-svg-jar/inlined/CloseX", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M4.72 4.72a.75.75 0 011.06 0L12 10.94l6.22-6.22a.75.75 0 111.06 1.06L13.06 12l6.22 6.22a.75.75 0 11-1.06 1.06L12 13.06l-6.22 6.22a.75.75 0 01-1.06-1.06L10.94 12 4.72 5.78a.75.75 0 010-1.06z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});