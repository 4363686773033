define("ember-svg-jar/inlined/Complete-Fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 2.25A9.75 9.75 0 1021.75 12 9.769 9.769 0 0012 2.25zm4.64 8.044l-5.493 5.25a.76.76 0 01-.525.206.722.722 0 01-.516-.206L7.36 12.919a.75.75 0 111.032-1.088l2.23 2.128 4.988-4.753a.75.75 0 011.032 1.088z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});