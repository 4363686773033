define("ember-svg-jar/inlined/Wash-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.43.886L12 1.5l-.43-.614a.75.75 0 01.86 0zM12 2.438A23.639 23.639 0 008.816 5.46C6.984 7.568 5.25 10.395 5.25 13.5a6.75 6.75 0 0013.5 0c0-3.105-1.734-5.932-3.566-8.04A23.632 23.632 0 0012 2.439zM11.57.885L12 1.5l.43-.614h.001l.002.002.005.003.02.014.068.05a21.96 21.96 0 011.131.9c.725.615 1.69 1.508 2.659 2.622 1.918 2.205 3.934 5.378 3.934 9.023a8.25 8.25 0 11-16.5 0c0-3.645 2.016-6.818 3.934-9.023a25.142 25.142 0 013.538-3.334 13.993 13.993 0 01.32-.238l.02-.014.005-.003.002-.002zm4.99 12.635a.75.75 0 01.612.866 5.24 5.24 0 01-4.289 4.278.75.75 0 11-.25-1.48 3.74 3.74 0 003.06-3.052.75.75 0 01.867-.612z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});