define("ember-svg-jar/inlined/ChatText", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M216 48H40a16 16 0 00-16 16v160a15.85 15.85 0 009.24 14.5A16.13 16.13 0 0040 240a15.89 15.89 0 0010.25-3.78.69.69 0 00.13-.11L82.5 208H216a16 16 0 0016-16V64a16 16 0 00-16-16zM40 224zm176-32H82.5a16 16 0 00-10.3 3.75l-.12.11L40 224V64h176zM88 112a8 8 0 018-8h64a8 8 0 010 16H96a8 8 0 01-8-8zm0 32a8 8 0 018-8h64a8 8 0 110 16H96a8 8 0 01-8-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "inherit",
      "viewBox": "0 0 256 256"
    }
  };
});