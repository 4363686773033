define("ember-svg-jar/inlined/Production-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.875 3.75a.75.75 0 01.75-.75h5.25a.75.75 0 01.75.75v15h.75a.75.75 0 010 1.5H2.625a.75.75 0 010-1.5h.75v-6a.75.75 0 01.75-.75h4.5V8.25a.75.75 0 01.75-.75h4.5V3.75zm0 5.25h-3.75v9.75h3.75V9zm1.5 9.75h3.75V4.5h-3.75v14.25zm-6.75 0V13.5h-3.75v5.25h3.75z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});