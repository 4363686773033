define("ember-svg-jar/inlined/Share-Outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.5 3a2.25 2.25 0 00-1.927 3.412.757.757 0 01.07.108A2.25 2.25 0 1016.5 3zm-2.685 4.868a3.75 3.75 0 10-.812-1.261L8.685 9.382a3.75 3.75 0 100 5.235l4.319 2.775a3.75 3.75 0 10.813-1.26l-4.32-2.776A3.74 3.74 0 009.75 12c0-.479-.09-.936-.253-1.357l4.318-2.775zm-5.929 2.905l.006.01A2.24 2.24 0 018.25 12a2.24 2.24 0 01-.375 1.244 2.25 2.25 0 11.01-2.471zm6.692 6.807a.79.79 0 00.06-.095 2.25 2.25 0 11-.06.095z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});