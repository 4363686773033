define("ember-svg-jar/inlined/Contract-Duo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path opacity=\".2\" d=\"M18.75 14.334l-3.45 3.45a.797.797 0 01-.713.197l-5.437-1.36a.75.75 0 01-.272-.14L3.75 12.478l3.056-5.747 4.81-1.406a.75.75 0 01.515.037l3.244 1.472h-1.94a.74.74 0 00-.526.216l-3.675 3.666a.759.759 0 00.085 1.134l.506.384a3.009 3.009 0 003.6 0l1.2-.9 4.125 3z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.822 6.046l.725.329a1.49 1.49 0 00-.167.143l-3.675 3.666-.008.008a1.51 1.51 0 00.168 2.255l.507.384.004.004a3.76 3.76 0 004.498 0l.001-.001.757-.568 2.967 2.159-2.815 2.815a.047.047 0 01-.034.009l-5.4-1.35-.01-.009-4.636-3.619 2.553-4.89 4.565-1.335zm-4.98-.107l4.566-1.335a1.5 1.5 0 011.032.075l3.096 1.405h1.537l2.18-1.09.003-.001a1.49 1.49 0 011.997.642l2.304 4.411v.002a1.5 1.5 0 01-.657 2.042l-2.178 1.085-1.4 1.645a.743.743 0 01-.042.045l-3.45 3.45a1.546 1.546 0 01-1.425.394l-5.437-1.36-.02-.005a1.499 1.499 0 01-.536-.276l-5.065-3.953L1.1 11.987a1.49 1.49 0 01-.658-2.033L2.747 5.54a1.502 1.502 0 011.987-.656l.008.003 2.1 1.051zm-3.415 5.537l2.305-4.415-1.654-.828-2.305 4.413 1.654.83zm17.147.097l1.657-.825-.002-.004-2.303-4.41-1.657.83 2.305 4.409zm-3.778-3.989H13.436L9.77 11.239a.009.009 0 00-.002.005.01.01 0 00.001.006.01.01 0 00.003.002l.503.383a2.26 2.26 0 002.7-.002l1.2-.899a.75.75 0 01.89-.007l3.563 2.592.722-.848-2.555-4.887zM4.683 16.758a.75.75 0 011.058-.075l2.117 1.835.004.002 2.822.712a.75.75 0 11-.368 1.454l-2.814-.71a1.443 1.443 0 01-.628-.325l-2.115-1.835a.75.75 0 01-.076-1.058z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});